<mat-toolbar class="toolbar-single">
 
  <div class="header-group">

	<button mat-icon-button *ngIf="!header?.locationBack" class="btn-menu mr-3" color="primary" (click)="toggleSidenav()">
		<mat-icon>menu</mat-icon>
	</button>
    <div class="page">
		<button 
			*ngIf="header?.locationBack"
			class="mr-2" 
			mat-icon-button 
			(click)="goBack()" >
			<mat-icon>arrow_back</mat-icon>
	 	</button>
		 <div>
			 <h1>{{header?.title}}</h1>
			 <small *ngIf="header?.subtitle" class="subtitle text-truncate">{{header?.subtitle}}</small>
		 </div>
	</div>


	<button mat-icon-button [matMenuTriggerFor]="menu">
		<div class="user-initials" mat>
			{{avatar}}
		</div>
		
	  </button>
	  <mat-menu #menu="matMenu">
		<button mat-menu-item disabled class="text-truncate" style="max-width: 242px;">
			Olá, {{ userName }}
		</button>
			<button mat-menu-item [routerLink]="['/configuracao']">
			<mat-icon>settings</mat-icon>
			<span>Configuração</span>
		  </button>
		  <!-- <button mat-menu-item [routerLink]="['/usuarios']">
			<mat-icon>manage_accounts</mat-icon>
			<span>Gestão de usuários</span>
		  </button> -->
		  <button mat-menu-item (click)="changePassword()">
			<mat-icon>password</mat-icon>
			<span>Alterar minha senha</span>
		  </button>
		  <button mat-menu-item (click)="goToServiceDesk()">
			<mat-icon>help_center</mat-icon>
			<span>Preciso de ajuda</span>
		  </button>
		<button mat-menu-item (click)="logout()">
		  <mat-icon>exit_to_app</mat-icon>
		  <span>Sair</span>
		</button>
	  </mat-menu>
  </div>

</mat-toolbar>