<img class="logo" width="130" height="21" rel="preconnect"  src="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/images/BROADFACTOR_MARCA_FUNDOCINZA_40%20(1).png"  alt="logo broadfactor">


<mat-nav-list class="mr-2">
  <mat-list-item 
    *ngFor="let item of listItems"  
    [routerLinkActive]="['active']" 
    [routerLink]="item.link"
    [hidden]="item?.hidden">

    <mat-icon  matListIcon>{{item.icon}}</mat-icon>
    <div matLine>{{item.linkTitle}}</div>
</mat-list-item>

</mat-nav-list>