import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Company, Partner } from '../interfaces/company';
import { Contact } from '../pages/settings/edit-contact/edit-contact.component';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  qualificationList = [
    {code: '05', name: 'Administrador'},
    {code: '08', name: 'Conselheiro de Administração'},
    {code: '10', name: 'Diretor'},
    {code: '16', name: 'Presidente'},
    {code: '17', name: 'Procurador'},
    {code: '20', name: 'Sociedade Consorciada'},
    {code: '21', name: 'Sociedade Filiada'},
    {code: '22', name: 'Sócio'},
    {code: '23', name: 'Sócio Capitalista'},
    {code: '24', name: 'Sócio Comanditado'},
    {code: '25', name: 'Sócio Comanditário'},
    {code: '26', name: 'Sócio de Indústria'},
    {code: '28', name: 'Sócio-Gerente'},
    {code: '29', name: 'Sócio Incapaz ou Relat.Incapaz (exceto menor)'},
    {code: '30', name: 'Sócio Menor (Assistido/Representado)'},
    {code: '31', name: 'Sócio Ostensivo'},
    {code: '37', name: 'Sócio Pessoa Jurídica Domiciliado no Exterior'},
    {code: '38', name: 'Sócio Pessoa Física Residente no Exterior'},
    {code: '47', name: 'Sócio Pessoa Física Residente no Brasil'},
    {code: '48', name: 'Sócio Pessoa Jurídica Domiciliado no Brasil'},
    {code: '49', name: 'Sócio-Administrador'},
    {code: '52', name: 'Sócio com Capital'},
    {code: '53', name: 'Sócio sem Capital'},
    {code: '54', name: 'Fundador'},
    {code: '55', name: 'Sócio Comanditado Residente no Exterior'},
    {code: '56', name: 'Sócio Comanditário Pessoa Física Residente no Exterior'},
    {code: '57', name: 'Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior'},
    {code: '58', name: 'Sócio Comanditário Incapaz'},
    {code: '59', name: 'Produtor Rural'},
    {code: '63', name: 'Cotas em Tesouraria'},
    {code: '65', name: 'Titular Pessoa Física Residente ou Domiciliado no Brasil'},
    {code: '66', name: 'Titular Pessoa Física Residente ou Domiciliado no Exterior'},
    {code: '67', name: 'Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor)'},
    {code: '68', name: 'Titular Pessoa Física Menor (Assistido/Representado)'},
    {code: '70', name: 'Administrador Residente ou Domiciliado no Exterior'},
    {code: '71', name: 'Conselheiro de Administração Residente ou Domiciliado no Exterior'},
    {code: '72', name: 'Diretor Residente ou Domiciliado no Exterior'},
    {code: '73', name: 'Presidente Residente ou Domiciliado no Exterior'},
    {code: '74', name: 'Sócio-Administrador Residente ou Domiciliado no Exterior'},
    {code: '75', name: 'Fundador Residente ou Domiciliado no Exterior'},
    {code: '00', name: 'Representante legal'},
  ]

  legalNatureList = [
    {code: '201-1', name: 'Empresa Pública'},
    {code: '203-8', name: 'Sociedade de Economia Mista'},
    {code: '204-6', name: 'Sociedade Anônima Aberta'},
    {code: '205-4', name: 'Sociedade Anônima Fechada'},
    {code: '206-2', name: 'Sociedade Empresária Limitada'},
    {code: '207-0', name: 'Sociedade Empresária em Nome Coletivo'},
    {code: '208-9', name: 'Sociedade Empresária em Comandita Simples'},
    {code: '209-7', name: 'Sociedade Empresária em Comandita por Ações'},
    {code: '212-7', name: 'Sociedade em Conta de Participação'},
    {code: '213-5', name: 'Empresário Individual'},
    {code: '214-3', name: 'Cooperativa'},
    {code: '215-1', name: 'Consórcio de Sociedades'},
    {code: '216-0', name: 'Grupo de Sociedades'},
    {code: '217-8', name: 'Estabelecimento, no Brasil, de Sociedade Estrangeira'},
    {code: '219-4', name: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira'},
    {code: '221-6', name: 'Empresa Domiciliada no Exterior'},
    {code: '222-4', name: 'Clube/Fundo de Investimento'},
    {code: '223-2', name: 'Sociedade Simples Pura'},
    {code: '224-0', name: 'Sociedade Simples Limitada'},
    {code: '225-9', name: 'Sociedade Simples em Nome Coletivo'},
    {code: '226-7', name: 'Sociedade Simples em Comandita Simples'},
    {code: '227-5', name: 'Empresa Binacional'},
    {code: '228-3', name: 'Consórcio de Empregadores'},
    {code: '229-1', name: 'Consórcio Simples'},
    {code: '230-5', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)'},
    {code: '231-3', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)'},
    {code: '232-1', name: 'Sociedade Unipessoal de Advogados'},
    {code: '233-0', name: 'Cooperativa de Consumo'},
  ]
  user: any;
  httpClient: any;


  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe(user => {this.user = user});
  }

  // COMPANY
  getCompany() {
    return this.http.get<Company>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId)
  }

  putCompany(company: Company) {
    return this.http.put<Company>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId, company)
  }

  patchCompany(basen: boolean, pep: boolean, percentage: number) {
    const obj = { "bacen": basen, "pep": pep, "percentage": percentage}
    return this.http.patch(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId, obj)
  }

  // ADDRESS

  getCompanyAddress() {
    return this.http.get(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/address')
  }
  putCompanyAdress(company: any) {
    return this.http.put<any>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/address', company)
  }


  // ACTIVITIES
  getCompanyActivities() {
    return this.http.get(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/activities')
  }

  addCompanyActivity(activity: any) {
    return this.http.post(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/activities', activity)
  }

  updateCompanyActivity(activity: any) {
    return this.http.put(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/activities/' +  activity.id, activity)
  }

  deleteCompanyActivity(activityId: any) {
    return this.http.delete<any>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/activities/' + activityId)
  }

  // CONTACT
  getCompanyContact() {
    return this.http.get<Contact[]>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/contact')
  }

  addCompanyContact(contact: any) {
    return this.http.post<Contact>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/contact', contact)
  }

  putCompanyContact(contact: any) {
    return this.http.put<Contact>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/contact/' + contact.id, contact)
  }

  deleteCompanyContact(contactId: any) {
    return this.http.delete(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/contact/' + contactId)
  }


  // PARTNER

  getCompanyPartners() {
    return this.http.get<Partner[]>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId +'/partners')
  }
  addCompanyPartner(partner: any) {
    return this.http.post<Partner>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId +'/partners', partner)
  }

  updateCompanyPartner(partner: any) {
    return this.http.put(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId +'/partners/' + partner.id, partner)
  }

  addPartnerDocument(files: Set<File>, type: string, idPartner: string) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));
    formData.append('documentType', type);

    return  this.http.put(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId +
            '/partners/' + idPartner + '/document', formData, {headers: {media: "true"}});
  }

  deleteCompanyPartner(partnerId: any) {
    return this.http.delete(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/partners/' + partnerId)
  }


  async putPartnersDocument(token: string, data: any){
    var formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("name",  data.file.name);
    formData.append("type", data.type);


    var requestOptions = {
      method: 'PUT',
      headers: { media: "true", 'Authorization':  'Bearer ' + token },
      body: formData,
      reportProgress: true,
      observe: 'events'
    };

    return fetch(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/partner/' + data.partner + '/document/update/' + data.id, requestOptions)
  }

  async postPartnersDocument(token: string, data: any){
    var formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("name",  data.file.name);
    formData.append("documentType", data.type);


    var requestOptions = {
      method: 'POST',
      headers: { media: "true", 'Authorization':  'Bearer ' + token },
      body: formData,
      reportProgress: true,
      observe: 'events'
    };

    return fetch(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/partner/' + data.partner + '/document/new', requestOptions)
  }





  // BANKS
  getCompanyBanks() {
    return this.http.get(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/banks')
  }
  putCompanyBanks(company: any) {
    return this.http.put<any>(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/banks', company)
    }


  // COMPANY DOCUMENTS

  getCompanyDocuments(body:any) {
    return this.http.post(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/documents/getAll', body)
  }

  getPartnerDocuments(partnerId: any) {
    return this.http.get(environment.API_url + 'secure-api/company/' + this.user?.tenant.companyId + '/partner/' + partnerId + '/documents')
  }
  async putCompanyDocument(token: string, data: any){
    var formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("name",  data.file.name);
    formData.append("documentType", data.type);

    var requestOptions = {
      method: 'PUT',
      headers: { media: "true", 'Authorization':  'Bearer ' + token },
      body: formData,
      reportProgress: true,
      observe: 'events'
    };

    return fetch(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/documents/update/' + data.id, requestOptions)
  }


  async uploadCompanyDocument( file: any, type: any) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append("name", file.name);
    formData.append("documentType", type);


    var requestOptions = {
      method: 'POST',
      headers: { media: "true", 'Authorization':  'Bearer ' + this.authService.user.value.token },
      body: formData,
      reportProgress: true,
      observe: 'events'
    };

    return fetch(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/documents', requestOptions)
  }

  uploadOthersDocument(files: Set<File>) {
    let formData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));
  

    var requestOptions = {
      method: 'POST',
      headers: { media: "true", 'Authorization':  'Bearer ' + this.authService.user.value.token },
      body: formData,
      reportProgress: true,
      observe: 'events'
    };

    return fetch(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/documents/others', requestOptions)

  }


  removeDocument(id: any) {
    return this.http.delete(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/documents/delete/' + id)
  }

  uploadOthersPartnerDocument( files: any,partnerId: any) {
    let formData = new FormData();
    files.map((file:any) => { formData.append('files', file, file.name); });

    var requestOptions = {
      method: 'POST',
      headers: { media: "true", 'Authorization':  'Bearer ' + this.authService.user.value.token },
      body: formData,
      reportProgress: true,
      observe: 'events'
    };

    return fetch(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/partner/' + partnerId + '/document/others', requestOptions)

  }


  removePartnerDocument(id: any, partnerId: any) {
    return this.http.delete(environment.API_url + 'secure-api/company/'+ this.user?.tenant.companyId + '/partner/' + partnerId + '/document/delete/' + id)
  }
}
