import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LayoutService } from './services/layout.service';
import { RouterService } from './services/router.service';
import { SidenavService } from './services/sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './services/auth.service';
import { use } from 'echarts';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('sidenav', {static: true}) public sidenav?: MatSidenav | any;
  currURL!: string;

  loading = false;
  hasSideLeft = false;
  currStateMenu = false;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;


  hasToolbar: boolean = false;
  subscriptionToolbar!: Subscription
  subscriptionRouteService!: Subscription;
  subscriptionSideLeft!: Subscription

  constructor(
    private layoutService: LayoutService,
    private routeService: RouterService,
    private router: Router,
    private sidenavService: SidenavService,
    private cdref: ChangeDetectorRef,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
  ) {


      this.mobileQuery = media.matchMedia('(max-width: 1023px)');
      this._mobileQueryListener = () => {
        changeDetectorRef.detectChanges()
        if(!this.mobileQuery.matches) {
          this.currStateMenu = this.hasSideLeft ? true : false;
          this.sidenavService.setSidenav(this.sidenav, this.currStateMenu)
          this.cdref.detectChanges();
        }
      };
      this.mobileQuery.addListener(this._mobileQueryListener);

      this.router.events.subscribe((e : RouterEvent | any) => {
        this.navigationInterceptor(e);
      });
  }

  ngAfterViewInit(): void {
    this.subscriptionSideLeft = this.layoutService.hasSideLeft
    .subscribe(hasSideLeft =>  {
      this.hasSideLeft = hasSideLeft;
      this.currStateMenu  = hasSideLeft;
      this.sidenavService.setSidenav(this.sidenav, this.currStateMenu)
      this.cdref.detectChanges();
    })
  }

  ngOnInit(): void {
    this.subscriptionRouteService = this.routeService.currURL.subscribe(currUrl => this.currURL = currUrl)
    this.subscriptionToolbar = this.layoutService.hasToolbar.subscribe(hasToolbar => this.hasToolbar = hasToolbar);
  }

  backdropClick() {
    this.sidenavService.toggle()
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) { this.loading = true }
    if (event instanceof NavigationEnd) { this.loading = false }
    if (event instanceof NavigationCancel) {  this.loading = false }
    if (event instanceof NavigationError) { this.loading = false }
  }

  ngOnDestroy(): void {
    this.subscriptionSideLeft.unsubscribe();
    this.subscriptionToolbar.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener)

  }
}
