import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { RoutePages } from '../enum/route-pages.enum';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  hasSideLeft = new BehaviorSubject<boolean>(true)
  hasToolbar = new BehaviorSubject<boolean>(true)
  subscribeRoute = new Subscription;
  header = new BehaviorSubject<{title: string, subtitle: string, locationBack?: string}>({title: '', subtitle: ''})

  constructor(private routeService: RouterService) { 
    this.subscribeRoute = this.routeService.currURL.subscribe(currRoute => {      
      if (currRoute) { this.currRoute(currRoute) }
    })

  }

  currRoute(route: string): void {
    this.hasSideLeft.next(!Boolean(hideSideLeftList.find((r: any) => r === route)));
    this.hasToolbar.next(!Boolean(hideToolbarList.find((r: any) => r === route)));
  }

  // HEADER
  setHeader(value: { title: string, subtitle: string, locationBack?: string}) {
    return this.header.next(value)
  }

  // MENU
  hideMenu(): void {
    this.hasSideLeft.next(false);
  }

  showMenu(): void {
    this.hasSideLeft.next(true);
  }

  // TOOLBAR
  hideToolbar(): void {
    this.hasToolbar.next(false);
  }

  showToolbar(): void {
    this.hasToolbar.next(true);
  }

  ngOnDestroy(): void {
    this.subscribeRoute.unsubscribe()
  }

}

const hideSideLeftList: any = [
  RoutePages.Root,
  RoutePages.Login,
  RoutePages.RecoverPassword,
  RoutePages.ChangePassword,
  RoutePages.ExternalProposal,
  RoutePages.Validation,
  RoutePages.SignUp,
  RoutePages.SettingsInitial,
  RoutePages.ResetPassword,
  RoutePages.SupplierDetail
]

const hideToolbarList: any = [
  RoutePages.Root,
  RoutePages.Login,
  RoutePages.RecoverPassword,
  RoutePages.ChangePassword,
  RoutePages.ExternalProposal,
  RoutePages.Validation,
  RoutePages.SignUp,
  RoutePages.SettingsInitial
]