<mat-sidenav-container class="container-sidenav" (backdropClick)="backdropClick()">
    <mat-sidenav
        #sidenav
        class="sidenav"
        [mode]="mobileQuery.matches ? 'over' : 'side'">
      <app-nav-list></app-nav-list>
    </mat-sidenav>


  <mat-sidenav-content class="content-sidenav" [ngClass]="{'m-0': !hasSideLeft}">
    <app-toolbar *ngIf="hasToolbar"></app-toolbar>
      <div class="container-outlet" [ngClass]="{'p-0': !hasSideLeft && !hasToolbar}">
        <router-outlet></router-outlet>
      </div>
  </mat-sidenav-content>

</mat-sidenav-container>
