import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  hide = true;
  hasError = false;
  errorMessage = '';
  showForgetPasswordPage = false;
  formLogin: FormGroup | any;
  loading = false;

  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router) {
    this.formLogin = this.fb.group({
      mail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
   }

  login() {
    this.loading = true;
    const mail = this.formLogin.get('mail').value;
    const password = this.formLogin.get('password').value;
    this.authService.login(mail, password).subscribe(
      () => {
        this.loading = false;
        this.router.navigate(['/dashboard']);
      },
      errorMessage => {
        this.hasError = true;
        this.loading = false;
        this.errorMessage = errorMessage ? errorMessage : 'O e-mail informado não está cadastrado ou a senha está incorreta. Verifique os dados digitados e tente entrar novamente.'
      }
    );
  }
}
