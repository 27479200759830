import { take } from 'rxjs/operators';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerStatus } from 'src/app/interfaces/user';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-disabled-user',
  templateUrl: './disabled-user.component.html',
  styleUrls: ['./disabled-user.component.css']
})
export class DisabledUserComponent implements OnInit {

  user?: User | any;
  loading: boolean = false;

  constructor(private authService: AuthService, private router: Router) { 
    this.authService.user.subscribe(user => {this.user = user})
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
  }

  goToServiceDesk() {
    window.open("https://teambroadfactor.atlassian.net/servicedesk/customer/portals", "_blank");
  }

  updateStatus() {
    this.loading = true;
    const status: any = {
      'NEW': () => {
        this.user.tenant.tenantStatus = CustomerStatus.NEW;
        this.authService.changeUser(this.user);
        this.router.navigate(['/configuracao-inicial']); 

      }, 
      'VALIDATING': () => {
        this.user.tenant.tenantStatus = CustomerStatus.VALIDATING;
        this.authService.changeUser(this.user);
        this.router.navigate(['/validacao']);
      }, 
      'PENDING': () => {
        this.user.tenant.tenantStatus = CustomerStatus.PENDING;
        this.authService.changeUser(this.user);
        this.router.navigate(['/dashboard']);
      }, 
      'ENABLED': () => {
        this.user.tenant.tenantStatus = CustomerStatus.ENABLED;
        this.authService.changeUser(this.user);
        this.router.navigate(['/dashboard']);
      }, 
      'BLOCKED': () => {
        this.user.tenant.tenantStatus = CustomerStatus.BLOCKED;
        this.authService.changeUser(this.user);
        this.router.navigate(['/validacao']);
      }, 
      'OVERDUE': () => {
        this.user.tenant.tenantStatus = CustomerStatus.OVERDUE;
        this.authService.changeUser(this.user);
        this.router.navigate(['/validacao']);
      }, 

    }

    this.authService.getStatus().pipe(take(1)).subscribe((resp: any) => {
      status[resp]();
      this.loading = false;
    })
  

   

  }
}
