<ng-container *ngIf="url && !loading && type != 'none'">

    <div class="embed">
        <div *ngIf="type == 'img'" class="actions">


            <div  [style.max-width.px]="'500'" [style.width.px]="'500'" class="text-truncate">
                {{fileName}}
            </div>

            <div>
                <button mat-icon-button [disabled]="zoomValue == 50" (click)="zoom('out')" matTooltip="Diminuir zoom">
                    <mat-icon>remove</mat-icon>
                </button>
                <input type="number" [style.width.px]="'80'" [(ngModel)]="zoomValue" class="mx-2">
                <button mat-icon-button (click)="zoom('in')" matTooltip="Aumentar zoom">
                    <mat-icon>add</mat-icon>
                  </button>

                <button mat-icon-button (click)="openInNewPage()" class="ml-2" matTooltip="Abrir em nova aba">
                  <mat-icon>open_in_new</mat-icon>
                </button>
            </div>
        </div>
        

        <div class="embed__file"  [style.height]="type == 'img' ? 'calc(100% - 64px)' : '100%'">
            <div class="img" *ngIf="type == 'img'">    
                <img [src]="url" [alt]="fileName"  [style.width.%]="zoomValue"> 
            </div>

            <object *ngIf="type == 'object'"
                [data]='url'
                type="application/pdf"
                width="100%"
                height="100%">
            </object>

    
            <iframe 
                *ngIf="type == 'iframe'" 
                width="100%" 
                height="100%" 
                [src]="url" 
                frameborder="0">
            </iframe>
            
        </div>
        
    </div>
    
</ng-container>

<app-blankslate *ngIf="!url && !loading && !fileName">
    <div text  style="max-width: 500px">
        <p>{{ blankslateMessage ? blankslateMessage : 'Selecione algum documento para visualizar.'}}</p>
    </div>
</app-blankslate>

 <app-blankslate *ngIf="type === 'none'  && !loading && fileName">
    <div text  style="max-width: 500px">
        <p>O arquivo <b>{{fileName}}</b> do tipo <b>{{fileType}}</b> não possui uma pré-visualização.</p>
        <button mat-flat-button *ngIf="type == 'none'" class="mt-2 px-3" color="primary" (click)="download()">Baixar arquivo</button>
    </div>
</app-blankslate>

<div class="spinner" *ngIf="loading">
    <div>
      <mat-spinner></mat-spinner>
      <p>Carregando</p>
    </div>
</div>