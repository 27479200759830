import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenav?: MatSidenav | any;
  constructor() {}

  public setSidenav(sidenav: MatSidenav, isOpen: boolean) {    
    this.sidenav = sidenav;
    this.sidenav.mode = window.innerWidth < 1025 ? 'over' : 'side';
    this.sidenav.opened = isOpen;
  }

  public open(sidenav: MatSidenav) {
    this.setSidenav(sidenav, true)
    return this.sidenav.open();
  }

  public close(sidenav: MatSidenav) {
    this.setSidenav(sidenav, false)
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }


}
