import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  currentApplicationVersion = environment.appVersion;
  currTab = 0;
  constructor(
    private activatedRoute: ActivatedRoute, private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.autoLogin()

    if (this.activatedRoute.snapshot.url[0].path === 'cadastro') {
      this.currTab = 1;
      window.history.replaceState({}, '', '/cadastro');
    }
  }

  onTabChanged(event: any) {
    if (event === 0) {
      window.history.replaceState({}, '', '/login');
    } else {
      window.history.replaceState({}, '', '/cadastro');
    }
    
    this.currTab = event;
  }

  goToServiceDesk() {
    window.open("https://teambroadfactor.atlassian.net/servicedesk/customer/portals", "_blank");
  }
}
