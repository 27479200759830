import { Tenant } from "src/app/interfaces/user";

export class User {
  constructor(
    public fullName: string,
    public phone: string,
    public mail: string,
    public id: string,
    public token: string,
    public userType = null,
    public enable: boolean,
    public lastLogin: number,
    public tenant: Tenant
  ) {}

}
