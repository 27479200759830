import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { Subscription } from 'rxjs/internal/Subscription';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss']
})
export class NavListComponent implements OnInit, OnDestroy {

  listItems: any;
  routerServiceSubscription?: Subscription | any;

  constructor(private routerService: RouterService) {}

  ngOnInit() {
   this.routerServiceSubscription = this.routerService.listItems  
    .subscribe(listItems => {
      this.listItems = listItems;
    });
  }

  ngOnDestroy(): void {
    this.routerServiceSubscription.unsubscribe();
  }

}
