import { MatDialog } from '@angular/material/dialog';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LayoutService } from 'src/app/services/layout.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { InitialsAvatarUtil } from 'src/app/utils/initial-avatar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy{

  header?: {title: string, subtitle: string, locationBack?: string}
  routerServiceDestroy?: Subscription | any;
  avatar: string;
  userName: any;

  constructor(
    private authService: AuthService, 
    private sidenavService: SidenavService, 
    private layoutService: LayoutService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog ) {
    this.userName = this.authService.user?.value?.fullName
    this.avatar = InitialsAvatarUtil.Do(this.userName);    
  }


  ngAfterViewInit(): void {
    this.routerServiceDestroy = this.layoutService.header
    .subscribe(header => { 
      this.header = header;
      this.cdref.detectChanges();
    })
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.routerServiceDestroy.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }

  goToServiceDesk() {
    window.open("https://teambroadfactor.atlassian.net/servicedesk/customer/portals", "_blank");
  }

  changePassword() {
    this.router.navigate(['/redefinir_senha'])

  }

  goBack() {
    this.router.navigate([this.header?.locationBack])
  }
}
