<div class="container-page">
  <div class="form-page">
    <h2>Recuperar senha</h2>
    <small class="text-secondary">Esqueceu a senha da sua conta? Não se preocupe, preencha o campo abaixo com o seu e-mail e iremos te enviar um link para recuperar sua senha</small>

    <div class="alert alert-success mt-3" role="alert" *ngIf="successResponse">
      E-mail para redefir senha enviado com sucesso.
    </div>

    <div class="alert alert-danger mt-3" role="alert" *ngIf="errorResponse">
      Ocorreu um erro ao tentar realizar o envio do e-mail. Gentileza tente novamente.
    </div>

    <mat-form-field appearance="outline" class="mt-3">
      <mat-label>E-mail</mat-label>
      <input matInput [(ngModel)]="mail" type="mail" placeholder="pat@example.com" required>
    </mat-form-field>

    <button class="mt-3 mb-5" mat-flat-button color="primary" [disabled]="!mail" (click)="sendMail()">Recuperar senha</button>

    <div class="d-flex">
      <small><a  [routerLink]="[ '/login' ]">Voltar para o Login</a></small>
    </div>

  </div>
</div>