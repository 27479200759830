<mat-sidenav #asideRight class="aside-right" disableClose position="end" [opened]="opened">
  <div class="header">
    <div class="d-flex justify-content-between">
      <h1>Detalhes</h1>
      <p><button mat-button (click)="close()"><mat-icon>close</mat-icon></button></p>
    </div>
    <ng-content select="[header]"></ng-content>

  </div>
  <div class="content"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    [scrollWindow]="false"
  >
    <ng-content select="[content]"></ng-content>
  </div>

  <div class="footer">
    <ng-content  select="[footer]"></ng-content>
  </div>
</mat-sidenav>

