import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Proposal } from 'src/app/interfaces/proposal';
import { Quotation } from 'src/app/interfaces/quotation';
import { ProposalService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-external-proposal',
  templateUrl: './external-proposal.component.html',
  styleUrls: ['./external-proposal.component.scss']
})
export class ExternalProposalComponent implements OnInit, OnDestroy {
  id?: string | any;
  routeSubscription: Subscription
  data: {
    proposal: Proposal;
    quotation: Quotation;
  } | any

  constructor(private route: ActivatedRoute, private proposalService: ProposalService) { 
    this.routeSubscription = this.route.params.subscribe((params: Params) =>  this.id = params['id']);
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.proposalService.getProposalById(this.id).pipe(take(1)).subscribe((resp: any) => this.data = resp )
  }

}
