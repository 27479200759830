import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserInterface } from '../interfaces/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  currURL = new BehaviorSubject<any>(undefined);
  routerActiveData = new BehaviorSubject(null);
  user?: UserInterface | any;

  listItems = new BehaviorSubject<any[]>([]);

  constructor(private router: Router, private auth: AuthService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(event.url);
        let urlWithoutParams =
          urlTree.root.children['primary']?.segments[0].path;
        if (!urlWithoutParams) {
          urlWithoutParams = 'login';
        }
        this.currURL.next(urlWithoutParams);
      }
    });

    this.auth.user.subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.listItems.next([]);

        const PAGES: any = {
          FINANCIAL_INSTITUTION: () =>
            this.listItems.next([
              {
                linkTitle: 'Dashboard',
                link: '/dashboard',
                icon: 'bar_chart',
                subtitle: '',
              },
              {
                linkTitle: 'Cotações',
                link: '/cotacoes',
                icon: 'description',
                subtitle: 'Lista de cotações',
              },
              {
                linkTitle: 'Propostas',
                link: '/propostas',
                icon: 'pending_actions',
                subtitle: 'Selecione uma proposta para ver os detalhes',
              },
              {
                linkTitle: 'Operação',
                link: '/operacao/geral',
                icon: 'military_tech',
                subtitle: 'Operações de crédito',
              },
              {
                linkTitle: 'Operação CCB',
                link: 'operacao/ccb',
                icon: 'military_tech',
                subtitle: 'Operações de crédito com CCB',
                hidden: !this.user.tenant.sellCcb,
              },
              {
                linkTitle: 'Gestão de usuários',
                link: '/usuarios',
                icon: 'group',
                subtitle: '',
                hidden:
                  this.user.userType !== 'ADMIN' &&
                  this.user.userType !== 'OWNER',
              },
              {
                linkTitle: 'Cadastro',
                link: '/configuracao',
                icon: 'settings',
                subtitle: '',
                hidden:
                  this.user.userType !== 'ADMIN' &&
                  this.user.userType !== 'OWNER',
              },
            ]),
          SUPPLIER: () =>
            this.listItems.next([
              {
                linkTitle: 'Dashboard',
                link: '/dashboard',
                icon: 'bar_chart',
                subtitle: 'Lista de cotações',
              },
              {
                linkTitle: 'Propostas',
                link: '/propostas',
                icon: 'pending_actions',
                subtitle: 'Lista de propostas',
              },
              {
                linkTitle: 'Cadastro',
                link: '/configuracao',
                icon: 'settings',
                subtitle: '',
              },
            ]),
        };

        user.tenant.companyType === 'SUPPLIER'
          ? PAGES[user.tenant.companyType]()
          : PAGES['FINANCIAL_INSTITUTION']();
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart | any) => {
        this.routerActiveData.next(
          this.listItems.value.filter((data) => data.link == event.url)[0]
        );
      });
  }
}
