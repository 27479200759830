import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: `
		
		<span class="cls_005" style="text-align: right;"><b>Página 1 de 4</b></span>    
				<span class="cls_002">CÉDULA DE CRÉDITO BANCÁRIO Nº <input type="text"></span>    
		
				<div style="text-align: right;">
					<input type="checkbox" id="pf" name="pf" value="pf">
					<label for="pf"> Pessoa Jurídica - PJ</label>
					<input type="checkbox" id="pj" name="pj" value="pj">
					<label for="pj"> Pessoa Física - PF</label>
				</div>
		
				<p class="title">I - EMITENTE</p>
		
				<div>
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">Nome/Razão Social</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								Marlete Maidana Rocha Werner
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 280px;">
							<p style="margin-bottom: 0; margin-top: 4px">CPF/CNPJ</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								13.995.981/0001-04
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 250px;">
							<p style="margin-bottom: 0; margin-top: 4px">Data de Nascimento</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								17/08/1996
							</div>
						</div>
					</div>
			
					<div style="width: 100%;">
						<p style="margin-bottom: 0; margin-top: 4px">Endereço</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
			
						</div>
					</div>
			
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">Bairro</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 600px;">
							<p style="margin-bottom: 0; margin-top: 4px">Cidade</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								São José do Vale do Rio Preto
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 100px;">
							<p style="margin-bottom: 0; margin-top: 4px">UF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								SC
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 200px;">
							<p style="margin-bottom: 0; margin-top: 4px">CEP</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								00000-000
							</div>
						</div>
					</div>
				</div>
		
		
				<p class="title">II - AVALISTA</p>
		
				<!-- 1 avalista -->
				<div>
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">1. Nome Completo</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 280px;">
							<p style="margin-bottom: 0; margin-top: 4px">CPF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								091.732.959-73
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 380px;">
							<p style="margin-bottom: 0; margin-top: 4px">Estado Civil</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								Lorem ipsum dolor sit
							</div>
						</div>
					</div>
			
					<div style="width: 100%;">
						<p style="margin-bottom: 0; margin-top: 4px">Endereço</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
			
						</div>
					</div>
			
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">Bairro</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 600px;">
							<p style="margin-bottom: 0; margin-top: 4px">Cidade</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								São José do Vale do Rio Preto
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 100px;">
							<p style="margin-bottom: 0; margin-top: 4px">UF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								SC
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 200px;">
							<p style="margin-bottom: 0; margin-top: 4px">CEP</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								00000-000
							</div>
						</div>
					</div>
				</div>
		
				<!-- 2 avalista -->
				<div style="margin-top: 20px;">
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">2. Nome Completo</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 280px;">
							<p style="margin-bottom: 0; margin-top: 4px">CPF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								091.732.959-73
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 380px;">
							<p style="margin-bottom: 0; margin-top: 4px">Estado Civil</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								Lorem ipsum dolor sit
							</div>
						</div>
					</div>
		
					<div style="width: 100%;">
						<p style="margin-bottom: 0; margin-top: 4px">Endereço</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
		
						</div>
					</div>
		
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">Bairro</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 600px;">
							<p style="margin-bottom: 0; margin-top: 4px">Cidade</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								São José do Vale do Rio Preto
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 100px;">
							<p style="margin-bottom: 0; margin-top: 4px">UF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								SC
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 200px;">
							<p style="margin-bottom: 0; margin-top: 4px">CEP</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								00000-000
							</div>
						</div>
					</div>
				</div>
		
				<!-- 3 avalista -->
				<div style="margin-top: 20px;">
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">3. Nome Completo</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 280px;">
							<p style="margin-bottom: 0; margin-top: 4px">CPF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								091.732.959-73
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 380px;">
							<p style="margin-bottom: 0; margin-top: 4px">Estado Civil</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								Lorem ipsum dolor sit
							</div>
						</div>
					</div>
		
					<div style="width: 100%;">
						<p style="margin-bottom: 0; margin-top: 4px">Endereço</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
		
						</div>
					</div>
		
					<div style="display: flex; width: 100%;">
						<div style="width: 100%;">
							<p style="margin-bottom: 0; margin-top: 4px">Bairro</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 600px;">
							<p style="margin-bottom: 0; margin-top: 4px">Cidade</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								São José do Vale do Rio Preto
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 100px;">
							<p style="margin-bottom: 0; margin-top: 4px">UF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								SC
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 200px;">
							<p style="margin-bottom: 0; margin-top: 4px">CEP</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								00000-000
							</div>
						</div>
					</div>
				</div>
		
		
				<br>
				<p class="title">III - INSTITUIÇÃO CREDORA</p>
				<span>BANCO DIGIMAIS S.A. – Instituição financeira inscrita no CNPJ sob Nº 92.874.270/0001-40 com sede na R. Elvira  Ferraz, 250 - conjunto 1102 CEP: 04552- 040, na cidade de São Paulo, SP, doravante denominado BANCO.</span>
				
				<p class="title">IV - CARACTERÍSTICAS DA CÉDULA</p>
		
				<div>
					<div style="display: flex; width: 100%; align-items: flex-end;">
						<div style="width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">1.Valor Solicitado R$</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 100px;">
							<p style="margin-bottom: 0; margin-top: 4px">2. Prazo</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								959
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">3 Valor Total Financiado R$</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">4. Valor da Parcela com Encargos R$</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">5. Valor Total com R$</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
					</div>
		
					<div style="display: flex; width: 100%; align-items: flex-end;">
						<div style="width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">6. No de Parcelas</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 100px;">
							<p style="margin-bottom: 0; margin-top: 4px">7. Primeiro Vencimento</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								10/01/2020
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">8. Último Vencimento</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								10/01/2025
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">9.Taxa de Juros Capitalizada</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								% a.m.
							</div>
						</div>
		
						<div style="border-left: 8px solid white; width: 25%;">
							<p style="margin-bottom: 0; margin-top: 4px">9.Taxa de Juros Capitalizada</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								% a.a.
							</div>
						</div>
					</div>
			
		
				</div>
		
				<p class="title">Pagamentos Autorizados</p>
		
				<div>
					<div style="display: flex; width: 100%; align-items: flex-end;">
						<div style="width: 33%;">
							<p style="margin-bottom: 0; margin-top: 4px">10. IOF</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 33%;">
							<p style="margin-bottom: 0; margin-top: 4px">11. Despesa de Cadastro</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								959
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 33%;">
							<p style="margin-bottom: 0; margin-top: 4px">12. Registros</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
		
					</div>
		
					<div style="display: flex; width: 100%; align-items: flex-end;">
						<div style="width: 66%;">
							<p style="margin-bottom: 0; margin-top: 4px">13. Soma total de pagamentos autorizados</p>
							<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
								R$ 22.222.222,00
							</div>
						</div>
			
						<div style="border-left: 8px solid white; width: 33%">
							<p style="margin-bottom: 0; margin-top: 4px">14.Custo Efetivo Total</p>
							<div style="display: flex;">
								<div style="background-color: #ccc; width: 50%; height: 20px; padding: 4px;">
									% a.m.
								</div>
								<div style="background-color: #ccc; width: 50%; height: 20px; padding: 4px;">
									% a.a
								</div>
							</div>
						</div>
		
		
					</div>
			
		
				</div>
		
				<!-- NOVA PÁGINA -->
				<div style=" display: flex; justify-content: space-between;">
					<div>
						<span class="cls_005">FOR_066 - Formulário Antecipa GOV Mai/2021</span> 
						<span class="cls_005"><span style="color: red">Ouvidoria:</span> 0800-6467600 ouvidoria@bancodigimais.com.br <span style="color: red;">SAC:</span> 4020.3300</span>
					</div>
					<p style="color: #cccccc;">NÃO NEGOCIÁVEL</p>
				</div>
		
		
				<span class="cls_005" style="text-align: right;"><b>Página 2 de 4</b></span>    
		
				<p class="title">V - GARANTIA</p>
				<div style="background-color: #ccc; width: 100%; height: 40px; padding: 4px;">
					
				</div>
		
				<p class="title">VI - SEGURO PRESTAMISTA OU OUTRA APÓLICE</p>
				<div style="background-color: #ccc; width: 100%; height: 40px; padding: 4px;">
					
				</div>
		
				<p class="title">VII - CONTAS BANCÁRIAS</p>
				<div style="display: flex; width: 100%;">
					<div style="width: 100%;">
						<p style="margin-bottom: 0; text-align: center;">Nome do banco</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
						
						</div>
					</div>
		
					<div style="border-left: 8px solid white; width: 150px;">
						<p style="margin-bottom: 0; text-align: center;">Agência</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
						</div>
					</div>
		
					<div style="border-left: 8px solid white; width: 150px;">
						<p style="margin-bottom: 0; text-align: center;">Conta Corrente</p>
						<div style="background-color: #ccc; width: 100%; height: 20px; padding: 4px;">
							
						</div>
					</div>
				</div>
		
				<br>
				<br>
		
				<div>
					1. Por esta CÉDULA bancária pagarei na data de seu vencimento ao BANCO ou a sua ordem, em moeda corrente nacional, o valor total solicitado acrescido de juros capitalizados mensalmente, encargos, IOF, despesas de cadastro e registros conforme previsto no Quadro IV, totalizando o valor indicado no item 5, apurado na data de emissão desta CÉDULA.
					
					<p style="display: block; margin-top: 8px; margin-left: 24px;">
						1.1.Declaro estar plenamente ciente de que a taxa de juros é a prevista no item 9 do Quadro IV, capitalizada mensalmente.
					</p>
				</div>
		
				<p>2. Declaro ter conhecimento do valor relativo a prestação de SERVIÇOS DIFERENCIADOS constante na tabela que se encontra nas dependências do BANCO e no site www.bancodigimais.com.br.</p>
		
				<p>3. O Custo Efetivo Total – CET, indicado no campo 14 do Quadro IV, representa as condições da operação de crédito vigentes na data de seu cálculo, sendo que para este cálculo foram considerados os fluxos referentes a liberação de recursos e aos pagamentos previstos, incluindo a taxa efetiva de juros capitalizados mensalmente indicada no item 9 do Quadro IV.</p>
		
				<p>4. Renuncio, desde já, a faculdade de realizar depósitos identificados ou não na conta corrente do BANCO, sem que este tenha expressamente autorizado esta forma de pagamento. Qualquer depósito feito em desacordo com esta cláusula não ensejará quitação.</p>
		
				<p>5. Autorizo o BANCO a efetuar o pagamento do valor líquido do crédito diretamente na conta corrente por mim indicada no Quadro VII.</p>
		
				<p>6. Com base na Resolução 4.558/17, incidirá comissão de permanência sobre o valor da parcela vencida, representada pela taxa de juros remuneratórios prevista no contrato, juros moratórios à razão de 1% ao mês e multa contratual de 2%, sem cumulação com qualquer outro encargo contratual.</p>
		
				<p>7. O valor presente dos pagamentos previstos para fins de amortização ou liquidação antecipada nos contratos de concessão de crédito e de arrendamento mercantil financeiro, contratados a taxas prefixadas deve ser calculado com a utilização da taxa de juros pactuada no contrato, conforme nova redação do art. 2° da Resolução 3516 dada pela Resolução 4320, de 27 de março de 2014.</p>
		
				<p>8. Em havendo divergência, inconsistência sistêmica ou não realização de repasse das parcelas por parte do Governo Federal, com base na garantia descrita no quadro V, o EMITENTE se compromete a realizar o pagamento das parcelas vencidas e vincendas, sob pena de vencimento antecipado da cédula, e consequente execução judicial da dívida.</p>
		
				<p>9. Declaro estar ciente que além das hipóteses estabelecidas em lei e nesta CÉDULA, o vencimento das parcelas será automaticamente antecipado, independente de qualquer notificação de caráter judicial ou extrajudicial, englobando parcelas vencidas e vincendas que serão imediatamente exigíveis, caso não cumprir qualquer obrigação pactuada, facultado ao BANCO exercer seus direitos sobre a(s) garantia(s) constituída(s), podendo promover as medidas judiciais cabíveis sem a minha consulta ou anuência.</p>
		
		
				<!-- NOVA PÁGINA -->
				<div style=" display: flex; justify-content: space-between;">
					<div>
						<span class="cls_005">FOR_066 - Formulário Antecipa GOV Mai/2021</span> 
						<span class="cls_005"><span style="color: red">Ouvidoria:</span> 0800-6467600 ouvidoria@bancodigimais.com.br <span style="color: red;">SAC:</span> 4020.3300</span>
					</div>
					<p style="color: #cccccc;">NÃO NEGOCIÁVEL</p>
				</div>        
				<span class="cls_005" style="text-align: right;"><b>Página 3 de 4</b></span>    
		
		
				<p>10. Declaro expressamente que o BANCO não tem nem terá qualquer responsabilidade por vícios ou defeitos nos bens ou serviços prestados na relação subjacente com o Governo Federal, suas entidades ou autarquias.</p>
		
				<p>11. Para assegurar e garantir o integral cumprimento de todas as obrigações assumidas nesta CÉDULA, e como condição para liberação e livre movimentação do crédito solicitado, dou ao BANCO a(s) garantia(s) pactuada(s) em instrumento anexo, por mim assinado, que passa a fazer parte integrante da presente CÉDULA.</p>
		
				<p>12. O BANCO poderá em caso de inadimplência cobrar todas as despesas e tarifas, inclusive de cobrança extrajudicial e honorários advocatícios de 10% (dez por cento) sobre o montante devido e, na hipótese de cobrança judicial, serão devidos os mesmos montantes acrescidos de custas processuais.</p>
		
				<p>13. Estou ciente de que na hipótese de descumprimento de qualquer cláusula desta CÉDULA, a eventual tolerância do BANCO não importará novação ou alteração contratual que o impedirá de exercer, a qualquer tempo, todos os direitos que lhe são assegurados.</p>
		
				<p>14. Se o produto da garantia não bastar para pagar o crédito do BANCO, acrescido dos encargos previstos nesta CÉDULA, subsistirá integralmente a minha responsabilidade e a do(s) AVALISTA(s) pelo pagamento do saldo devedor, o qual poderá ser exigido através de ação própria.</p>
		
				<p>15. Autorizo desde já o BANCO a ceder ou transferir os direitos decorrentes desata cédula a terceiros identificados, como poderá dar a presente em garantia de operações de refinanciamento ou outras legalmente permitidas, sem qualquer ônus para o EMITENTE e sem modificar as condições desta cédula, independente de notificação.</p>
		
				<span>16. Autorizo expressamente o BANCO a proceder notificações, comunicações e demais informações pelo endereço eletrônico e também por meio do número do celular informado, que deverá ser mantido atualizado pelo EMITENTE.
		
					<p style="display: block; margin-top: 8px; margin-left: 24px;">
						16.1. A autorização compreende também o encaminhamento de mensagens, comunicações, notificações, informações, pelo número do celular informado e os correspondentes aplicativos de mensagens instantâneas.
					</p>
		
				</span>
		
				<p>17. Se os bens ou direitos dados em garantia tornarem-se insuficientes para assegurar o cumprimento das obrigações principais ou acessórias de minha responsabilidade, será obrigatória a substituição, reposição, complementação ou reforço de garantia no prazo de 30 (trinta) dias a contar da data do ocorrido de forma que seja mantida a proporção existente na época da assinatura da CÉDULA entre o valor da garantia e o saldo devedor.</p>
		
				<span>18. Em garantia das obrigações assumidas, o EMITENTE poderá contratar seguro prestamista, através do qual o BANCO figurará como beneficiário e será o responsável pelo pagamento de seguro para fins de quitação das parcelas vincendas do contrato, em caso de morte, invalidez permanente ocasionada por acidente, devendo observar as exigências e restrições da seguradora. Para tanto é dado ao EMITENTE a opção de contratar a apólice de seguro coletiva disponibilizada pelo BANCO em nome dos segurados, podendo este representá-lo perante a seguradora, conforme opção indicada no Quadro VII.
		
					<p style="display: block; margin-top: 8px; margin-left: 24px;">
						18.1. Em caso do EMITENTE decidir por outra opção de seguro caberá ao BANCO avaliar e aceitar o seguro que o EMITENTE venha a contratar, devendo as condições de cobertura e os termos da respectiva apólice ser previamente submetidas ao BANCO.
					</p>
		
					<p style="display: block; margin-top: 8px; margin-left: 24px;">
						18.2. Na opção pela contratação da apólice disponibilizada pelo BANCO, o EMITENTE declara estar ciente e de acordo com as Condições Particulares do Seguro, as quais lhe foram apresentadas neste ato, aderindo de acordo com os seus termos.
					</p>
		
					<p style="display: block; margin-top: 8px; margin-left: 24px;">
						18.3. Declaro estar ciente de que se encontra a minha disposição no BANCO as condições gerais do seguro ora contratado.
					</p>
		
					<p style="display: block; margin-top: 8px; margin-left: 24px;">
						18.4. Declaro que até o presente momento, estou em perfeitas condições de saúde e em plena
						atividade profissional e não possuo doenças pré-existentes de meu conhecimento.
					</p>
				</span>
		
				<!-- NOVA PÁGINA -->
				<div style=" display: flex; justify-content: space-between;">
					<div>
						<span class="cls_005">FOR_066 - Formulário Antecipa GOV Mai/2021</span> 
						<span class="cls_005"><span style="color: red">Ouvidoria:</span> 0800-6467600 ouvidoria@bancodigimais.com.br <span style="color: red;">SAC:</span> 4020.3300</span>
					</div>
					<p style="color: #cccccc;">NÃO NEGOCIÁVEL</p>
				</div>
				
				<span class="cls_005" style="text-align: right;"><b>Página 4 de 4</b></span>    
		
				<p>19. Declaro para os devidos fins que estou ciente e autorizo o BANCO, em caráter irrevogável e irretratável, a repassar e consultar informações junto ao SERASA, entidades de proteção ao crédito, Banco Central do Brasil, centrais de risco e de informações de restrições cadastrais e cadastros positivos ou qualquer outro banco de dados cadastrais.</p>
		
				<p>20. Estou ciente de que não posso ceder ou transferir os direitos ou obrigações decorrentes desta CÉDULA, sem expressa anuência do BANCO, e de que é vedada a dação em pagamento, ou outra forma de alienação, seja do bem dado em garantia(s) ou da(s) garantia(s) adicional(ais), se houver.</p>
		
				<p>21. Respeitando o critério insculpido no art. 10, §2o da Medida Provisória 2.200-2/2001, as partes concordam entre si que o presente instrumento seja assinado de forma eletrônica, podendo ser conferida a sua autenticidade a qualquer momento, ato pelo qual não poderão evocar vício quanto à forma ou consentimento.</p>
		
				<p>22. Em virtude do financiamento a mim concedido, responsabilizo-me em manter constantemente atualizado por escrito junto ao BANCO o meu endereço para efeito de comunicação de qualquer ato ou fato decorrente desta CÉDULA</p>
		
				<p>23. O AVALISTA é garantidor de todas as obrigações assumidas nesta CÉDULA, responsabilizando-se solidária e incondicionalmente por todas as obrigações decorrentes da presente na qualidade de principal(ais) pagador(es), renunciando a qualquer benefício de ordem, e declara ter conhecimento de todas as cláusulas e condições previstas nesta CÉDULA.</p>
		
				<p>24. Para os litígios porventura decorrentes desta CÉDULA, pode o BANCO optar, a seu exclusivo critério, pelo foro da comarca de São Paulo, do meu domicílio ou do(s) AVALISTA(s).</p>
		
				<p>25. Firmamos a presente em 03 (três) vias idênticas, sendo a via do BANCO a ÚNICA NEGOCIÁVEL.</p>
		
				<br>
				<br>
		
				<p>São Paulo, _________________________________ </p>
				<br>
				<br>
		
		
				<div style="display: flex;">
					<div style="width: 50%;">
						_________________________________________ <br>
						<span style="display:block" class="cls_003">Emitente _______________</span>
						<br>
						_________________________________________ <br>
						<span style="display:block" class="cls_003">Avalista _______________</span>
						<br>
					</div>
					<div style="width: 50%;">
						_________________________________________ <br>
						<span style="display:block" class="cls_003">Emitente _______________</span>
						<br>
						_________________________________________ <br>
						<span style="display:block" class="cls_003">Avalista _______________</span>
						<br>
					</div>
				</div>
				
		
				<!-- NOVA PÁGINA -->
				<div style="
				display: flex;
				justify-content: space-between;
				height: 50px;
				padding-top: 40px;">
					<div>
		
						<span class="cls_005">FOR_066 - Formulário Antecipa GOV Mai/2021</span> 
						<span class="cls_005"><span style="color: red">Ouvidoria:</span> 0800-6467600 ouvidoria@bancodigimais.com.br <span style="color: red;">SAC:</span> 4020.3300</span>
					</div>
					<p style="color: #cccccc;">NÃO NEGOCIÁVEL</p>
				</div>
	`,
	styles: [
		'h2, .none-message { color: red; }',
		'body { font-family: helvetica; font-size: 13px; padding: 8px;}',
		'.d-block {display: block}',
		'span.cls_002{font-size:16px; display: block; text-align: center; margin-bottom: 28px; font-weight:bold;font-style:normal;text-decoration: none}',
		'span.cls_003{font-weight:normal;font-style:normal;text-decoration: none}',
		'div.cls_003{font-weight:normal;font-style:normal;text-decoration: none}',
		'span.cls_004{font-weight:bold;font-style:normal;text-decoration: none}',
		'div.cls_004{ font-weight:bold;font-style:normal;text-decoration: none}',
		'span.cls_005{ display: block; font-size:12px; ;font-weight:bold;font-style:normal;text-decoration: none}',
		'div.cls_005{ display: block; font-size:12px; ;font-weight:bold;font-style:normal;text-decoration: none}',
		'.title {color: #d80000; font-weight: bold; text-align: center; margin-bottom: 4px;}'
	],
})
export class MyComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
