import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';

import { SignupComponent } from './pages/auth/signup/signup.component';
import { SharedModule } from './modules/_shared.module';
import { NavListComponent } from './components/nav-list/nav-list.component';
import { ExternalProposalComponent } from './pages/proposal/external-proposal/external-proposal.component';
import { ForgetPasswordComponent } from './pages/auth/login/forget-password/forget-password.component';
import { ChangePasswordComponent } from './pages/auth/login/change-password/change-password.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DisabledUserComponent } from './components/disabled-user/disabled-user.component';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { AddPartnerModalComponent } from './components/add-partner-modal/add-partner-modal.component';
import { DeleteConfirmModalComponent } from './components/delete-confirm-modal/delete-confirm-modal.component';
import { NewProposalConfirmModalComponent } from './pages/quotation/components/new-proposal-confirm-modal/new-proposal-confirm-modal.component';
import { MessageErrorComponent } from './components/message-error/message-error.component';
import { NotifierComponent } from './components/notifier/notifier.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SignupComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    NavListComponent,
    ToolbarComponent,
    ResetPasswordComponent,
    ExternalProposalComponent,
    DisabledUserComponent,

    AddPartnerModalComponent,
    DeleteConfirmModalComponent,
    NewProposalConfirmModalComponent,
    MessageErrorComponent,
    NotifierComponent


  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule {}
