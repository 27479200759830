<div class="container">
  <div class="container-item">
    <span class="container-item-message">
      {{ data.message }}
    </span>
    <span class="container-item-alternative">
      {{ data.altMessage }}
    </span>
    <div *ngIf="data.notifierList" class="d-flex flex-column">
      <span *ngFor="let item of data.notifierList" class="container-item-alternative">
       - {{ item.message }}
      </span>
    </div>
  </div>

  <button
    *ngIf="data.buttonTxt"
    (click)="snackBarRef.dismiss()"
    mat-stroked-button
  >
    {{ data.buttonTxt }}
  </button>
</div>
