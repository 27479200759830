
<h2 mat-dialog-title class="mb-1"><b>Confirmar Proposta</b></h2>

<mat-dialog-content >
  <header>
    <h3 mat-dialog-title class="mb-0">{{data.supplier?.supplierName}}</h3>
    <span class="text-muted">{{data.supplier?.supplierCnpj ?? '' | mask : "CPF_CNPJ"}}</span>
  </header>

  <main *ngIf="!isLoading && !hasError">
    <table class="my-3">
      <tr>
        <td>Valor Líquido</td>
        <td>{{data.formValue.netValue | currency: 'BRL'}}</td>

      </tr>
      <tr>
        <td>IOF</td>
        <td>{{data.formValue.iofValue | currency: 'BRL'}}</td>

      </tr>
      <tr>
        <td>Número de Parcelas</td>
        <td>{{data.formValue.installmentNumber}}</td>

      </tr>
      <tr>
        <td>Data da Primeira Parcela</td>
        <td>{{data.formValue.firstInstallmentDate | date: "dd/MM/yyyy"}}</td>
      </tr>
      <tr>
        <td>Valor da Parcela</td>
        <td>{{data.formValue.installmentValue | currency: 'BRL'}}</td>

      </tr>
      <tr>
        <td>Taxa de Juros</td>
        <td>{{data.formValue.interestRate}}%</td>
      </tr>
      <tr>
        <td>Custo Efetivo Total</td>
        <td>{{cet}}%</td>
      </tr>

      <tr>
        <td><b class="h6">Valor Total do Empréstimo</b></td>
        <td><b class="h6">{{data.formValue.loanValue | currency: 'BRL'}}</b></td>
      </tr>
    </table>
  </main>

  <div
    *ngIf="isLoading || hasError"
    class="container-fluid d-flex justify-content-center align-items-center container-load-error"
  >
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <app-message-error
      *ngIf="hasError"
      message="Ocorreu um erro ao calcular o Custo Efetivo Total."
      altMessage="Verfique os dados digitados na proposta.">
    </app-message-error>
  </div>


</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
  <button mat-stroked-button [mat-dialog-close]="false">Cancelar</button>
  <button mat-flat-button
    color="primary"
    [mat-dialog-close]="cet"
    [disabled]="isLoading || hasError"
  >
    Salvar e Enviar
  </button>
</mat-dialog-actions>

