import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


@Component({
  selector: 'app-viewer-documents',
  templateUrl: './viewer-documents.component.html',
  styleUrls: ['./viewer-documents.component.scss']
})
export class ViewerDocumentsComponent implements OnInit {

	isDownloading: boolean = false;
	editorConfig: AngularEditorConfig = {
		editable: true,
		height: '68vh',
		translate: 'no',
		sanitize: true,
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
			['link',
			'unlink',
			'insertImage',
			'insertVideo',
			'insertHorizontalRule',
			'toggleEditorMode',  
			'fontSize',
			'backgroundColor',
			'customClasses',],
			[ 'justifyFull', 'fontName', 'textColor']
		],
		customClasses: []
	};

	@Input() footer?: any;
	@Input() header?: any;

	_html?: HTMLElement | any;
	@Input() set html(value: HTMLElement | any) {
		this._html = value;
		this.editable = !!value; 
	}

	_editable?: boolean;
	@Input() set editable(value: boolean) {
		if (this._editable != value) {
			this._editable = value
			value ? this.editorConfig.showToolbar = true : this.editorConfig.showToolbar = false;
		};
	}

	
	@Input() set donwloadPDF(value: boolean) {
		if (value) {
			this.exportAsPDF();
		};
	}

	currentPage: any
	container: any
	lastPage: any;
	isZommed: boolean = false;
	heightDefaultPage = 256;

	constructor() { }

	ngOnInit() {}

	addPage() {
		const page = document.createElement("div");
		page.classList.add("page-document")
		this.container.appendChild(page);
		return page;
	}

	getHeight(elem: any) {    
		return Math.round(elem.scrollHeight / 4.41414141);
	}

	exportAsPDF() {
		const bkpContent: any = document.querySelector(".angular-editor-textarea")?.cloneNode(true).getRootNode();

		this.isDownloading = true;
		this.updatePages();

		const pages = this.container.querySelectorAll(".page-document");
		let page: jsPDF = new jsPDF("p", "mm", "a4", true);

		pages.forEach((element: any, index: number) => {  
			this.toCanvas(element, page, bkpContent, index, pages.length)
		})

	}

	updatePages() {
		this.container = document.querySelector(".angular-editor-textarea");
		this.currentPage = document.querySelector(".angular-editor-textarea .page-document");

		this.lastPage = null;
		let height = this.getHeight(this.currentPage);
		
		if (height > this.heightDefaultPage)
			this.lastPage = this.addPage();
	
		while (height > this.heightDefaultPage) {   
			var lastNode = this.currentPage.childNodes[this.currentPage.childNodes.length - 1];
			this.lastPage.insertBefore(lastNode, this.lastPage.firstChild);

			height = this.getHeight(this.currentPage);
			if (height <= this.heightDefaultPage) {
				this.currentPage = this.lastPage;
				height = this.getHeight(this.currentPage);
				if (height > this.heightDefaultPage) {
					this.lastPage = this.addPage();        
				}
			}
		}
	
	}

	toCanvas(element: any, page: any, bkpContent: any, index: number, pageLenght: number){
		const options = { background: 'white',  scale: 3};
	
		html2canvas(element, options).then(canvas => {
		
			// IMG HEADER = max 30 height
			if (this.header) {
				let imgHeader = new Image;
				imgHeader.src = this.header; 
				imgHeader.crossOrigin = "*"
				imgHeader.setAttribute('Access-Control-Allow-Origin', '*')
				imgHeader.setAttribute('Access-Control-Allow-Methods', 'GET, PUT, POST')
				imgHeader.setAttribute('Access-Control-Max-Age', '5000')
				page.addImage(imgHeader, 'png', 20, 2, 40, 20) 
			}

			page.setFontSize(8)
			page.text('Página ' +( index + 1 ) + ' de ' + pageLenght, 180, 8)

			// IMG FOOTER
			if(this.footer) {
				let imgFooter = new Image;
				imgFooter.src = this.footer; 
				imgFooter.crossOrigin = "*"
				page.addImage(imgFooter, 'png', 20 , 278, 30, 20)
			}

		
			// IMG PAGE
			let img  = canvas.toDataURL('image/jpeg', 2 )
			let pdfWidth = 210;
			let pdfHeight = this.getHeight(element) //- 44
			page.addImage(img, 'JPEG', 0, 24, pdfWidth, pdfHeight, undefined, 'MEDIUM');


			if (index + 1 < pageLenght) {
				page.addPage()
			} else {
				page.save();
				this.html = null ;
				setTimeout(() => {
					this.isDownloading = false;
					this.donwloadPDF = false;;
					this.html = bkpContent.innerHTML
				}, 1);
			} 
		})
	}
	
}
