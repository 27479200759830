
<mat-accordion multi *ngIf="documentList.length">
  <mat-expansion-panel *ngFor="let doc of documentList; let i = index">
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <mat-icon class="me-4" style="font-size: 24px;">
            <span
              *ngIf="!!doc.files[0] && !doc.files[0]?.isDateUntil"
              [ngClass]="doc?.invalidUntilProperties?.class"
              [matTooltip]="doc?.invalidUntilProperties?.tooltip"
            >
              {{doc?.invalidUntilProperties?.icon || ''}}
            </span>
          </mat-icon>
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="me-2">
                {{doc.description}}
              </span>
              <mat-icon
                [ngClass]="!!doc.files.length && !!doc.files[0].name ? 'text-success' : 'text-danger' "
                class="ml-1"
              >
                {{ !!doc.files.length && !!doc.files[0].name  ? "check_circle" : "error" }}
              </mat-icon>
            </div>
            <span *ngIf="doc?.files?.length && !!doc.files[0].name ">
              <span class="text-muted">{{doc?.files?.length }} documento(s) importado(s) </span>
              <span class="text-muted" *ngIf="!!doc?.files[0]?.createdAt">| Data de inclusão: {{doc.files[0].createdAt}}  </span>
            </span>
            <span *ngIf="!doc?.files?.length || !doc.files[0].name " class="text-danger">Documento pendente</span>

          </div>
        </div>
        <div>
          <button mat-stroked-button color="accent"
            (click)="fileInput.click()" type="button"
            *ngIf="!doc.isLoading"
          >
            <span>Importar</span>
            <input class="d-none" type="file"
              #fileInput
              accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
              [multiple]="doc.type == 'OTHERS' || 'CCB' || 'TERMO_CESSAO' || 'TERMO_MUDANCA_DOMICILIO_BANCARIO'"
              (change)="addDocument($event, doc.type, i)"
            >
          </button>
          <button mat-flat-button class="ml-3 mr-1" color="accent" *ngIf="doc.isLoading" disabled>
            IMPORTANDO
            <mat-spinner class="d-inline-block ml-3" [diameter]="20"></mat-spinner>
          </button>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>


    <p
      class="d-flex align-items-center"
      *ngFor="let file of doc.files; let j = index"
    >
      <button mat-icon-button color="warn" (click)="deleteDocument(file.id, i, j)">
        <mat-icon class="mat-18"  *ngIf="doc?.canDelete && !file?.isDeleting">delete</mat-icon>
        <mat-spinner class="d-inline-block mr-2" [diameter]="20" *ngIf="file?.isDeleting"></mat-spinner>
      </button>
      <span>{{file.name}}</span>
    </p>

  </mat-expansion-panel>
</mat-accordion>
