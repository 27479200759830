export class InitialsAvatarUtil {
    
    static Do(text: string): string {

        let initials = '';
        if (text) {
            let baseText = text
            let arrName = baseText.trim().split(' ');
            if (arrName.length > 0) {
                initials = arrName[0][0].toUpperCase();
                initials = initials.concat((arrName.length > 1) ? arrName[1][0].toUpperCase() : arrName[0][1].toUpperCase())
            }
        }
        return initials;
    }
}