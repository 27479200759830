import { LayoutService } from 'src/app/services/layout.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  myForm: FormGroup | any;
  hide?: any = true;
  id : string | any;
  matcher = new MyErrorStateMatcher();
  successResponse = false;
  errorResponse = false;

  constructor(private layoutService: LayoutService, private formBuilder: FormBuilder, private authService: AuthService) { 
    this.myForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['']
    }, { validator: this.checkPasswords });

  }

  ngOnInit() {
    this.layoutService.setHeader({title: 'Alterar senha', subtitle: '', locationBack: '/cotacoes'})
    this.layoutService.hideMenu();
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmNewPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }


  changePassword() {
    const oldPassword = this.myForm.get('oldPassword').value;
    const newPassword = this.myForm.get('newPassword').value;
    this.id = this.authService.user.value.id;

    this.authService.passwordUpdate(this.id , oldPassword, newPassword).subscribe(
      (ok) => {
        this.successResponse = true;
      },
      (err) => {        
        this.errorResponse = true;
      }
    )
  }
}
