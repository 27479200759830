<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{label ? label :  'Banco'}}</mat-label>

  <mat-select required [formControl]="bankCtrl" placeholder="Banco" #singleSelect>
    <mat-option>
      <ngx-mat-select-search 
        [formControl]="bankFilterCtrl" 
        noEntriesFoundLabel="'Banco não encontrado'"
        placeholderLabel="Procurar banco..." >
      </ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
      {{ bank.COMPE }} - {{ bank.LongName}}
    </mat-option>
  </mat-select>
</mat-form-field>
