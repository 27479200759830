import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProposalService } from 'src/app/services/proposal.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Quotation } from 'src/app/interfaces/quotation';


@Component({
  selector: 'app-new-proposal-confirm-modal',
  templateUrl: './new-proposal-confirm-modal.component.html',
  styleUrls: ['./new-proposal-confirm-modal.component.css']
})
export class NewProposalConfirmModalComponent implements OnInit {

  isLoading = false;
  hasError = false;
  cet!: number;

  constructor(
    private proposalService: ProposalService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {formValue: any, supplier: Quotation},
    public notifier: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getCET();
  }

  getCET(){
    this.isLoading = true
    this.hasError = false;

    const payload: PayloadCET = {
      firstInstallmentDate: moment(this.data.formValue.firstInstallmentDate).format('DD/MM/YYYY'),
      installmentValue: this.data.formValue.installmentValue,
      lastInstallmentDate: moment(this.data.formValue.lastInstallmentDate).format('DD/MM/YYYY'),
      numberOfInstallments: this.data.formValue.installmentNumber,
      totalValue: this.data.formValue.netValue
    }

    this.proposalService.getPercentageCET(payload).subscribe({
      next: (response: any) => {
        this.cet = response;
        this.isLoading = false
        this.hasError = false;
      },
      error: () => {
        this.hasError = true;
        this.isLoading = false
      }
    })
  }
}


interface PayloadCET {
  firstInstallmentDate: string;
  installmentValue: number;
  lastInstallmentDate: string;
  numberOfInstallments: number;
  operationId?: string;
  totalValue: number;
}
