<form class="form-page" [formGroup]="formLogin"  *ngIf="!showForgetPasswordPage">

  <div class="pb-2">
    <mat-form-field appearance="outline">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="mail" required>
      <mat-error *ngIf="formLogin.get('mail')?.invalid">Informe um e-mail válido</mat-error>
    </mat-form-field>
  </div>

  <div class="pb-3">
    <mat-form-field appearance="outline">
      <mat-label>Senha</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required autocomplete="on">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-hint>Mínimo 6 dígitos</mat-hint>
    </mat-form-field>
  </div>


    <div class="alert alert-danger" role="alert" *ngIf="hasError">
      {{ errorMessage }}
    </div>
  
  <button class="mt-3 mb-5 w-100 py-2" mat-flat-button color="primary" *ngIf="!loading" [disabled]="!formLogin.valid" (click)="login()">Entrar</button>

  <button class="mt-3 mb-5 w-100 py-2" mat-flat-button color="primary" disabled *ngIf="loading"
    (click)="login()">
      Entrando <mat-spinner class="d-inline-block ml-2" [diameter]="20"></mat-spinner>
  </button>

  
  <mat-hint><a [routerLink]="[ '/recuperar_senha' ]">Esqueceu a senha? </a></mat-hint>
</form>