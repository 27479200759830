import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-add-partner-modal',
  templateUrl: './add-partner-modal.component.html',
  styleUrls: ['./add-partner-modal.component.css']
})
export class AddPartnerModalComponent {

  formsPartner!: FormGroup;
  qualificationList = this.companyService.qualificationList;
  bool = false;
  @Output() add = new EventEmitter(false);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddPartnerModalComponent>,
    private companyService: CompanyService,
  ){
    this.formsPartner = this.formBuilder.group({
      cpf: [null, Validators.required],
      name: [null, Validators.required],
      phone: [null, Validators.required],
      mail: [null, Validators.required],
      qualificationCode: [null, Validators.required],
      isSolidaryPartner: [false],
      isSubscriber:[false],
    })
  };

  save(){
    console.log(this.formsPartner.value)
    if(!this.formsPartner.valid) return;
    this.add.emit(this.formsPartner.value);
    this.dialogRef.close();
  }

}
