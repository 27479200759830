export interface UserInterface {
    fullName: string,
    cpf: string,
    mail: string,
    id: string,
    token: string,
    userType: string,
    enable: boolean
    lastLogin: number,
    tenant: Tenant,
}


export interface Tenant {
    companyId: string,
    companyType: string,
    id: string
    companyName: string,
    companyCnpj: string,
    companyCompletionPercentage: number;
    tenantStatus: any,
    sellCcb: boolean
    ccbBuyer: boolean;
}

export enum CustomerStatus {
    NEW = 'NEW',                // QUANDO O CLIENTE CADASTRA E NAO FINALIZA O CADASTRO ** ??? **
    VALIDATING = 'VALIDATING',  // QUANDO O CLIENTE ESTA AGUARDANDO SER ATIVO NO SISTEMA (BLOQUEADO NA PLATAFORMA)
    PENDING = 'PENDING',        // QUANDO O CLIENTE PRECISA MANDAR ALGUM DOCUMENTO FALTANTE, OU QUE FOI ENVIADO ERRONEAMENTE (VOLTA PARA O CADASTRO) 
    ENABLED = 'ENABLED',        // ATIVO NA PLATAFORMA, CLIENTE DISPONIVEL NO SISTEMA
    BLOCKED = 'BLOCKED',        // BLOQUEADO. NAO TEM ACESSO AO SISTEMA. 
    OVERDUE = 'OVERDUE'         // CLIENTE ESTA PENDENTE DE PAGAMENTO,  BLOQUEADO NA PLATAFORMA. 
 }