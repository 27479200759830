import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { DateUntilClass } from 'src/app/utils/date-until';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.css']
})
export class ExpansionPanelComponent implements OnInit {
  @Input() documentList:any[] = [];
  @Output() add = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  addDocument(eventFile:any, type: string, indexType: number){
    this.add.emit({eventFile, type, indexType});
  }

  deleteDocument(id:string, typeIndex:number, fileIndex:number){
    this.delete.emit({id, typeIndex, fileIndex});
  }
}
