

	<mat-tab-group headerPosition="below" [selectedIndex]="selectedIndex" dynamicHeight>
		<form class="form-page" [formGroup]="signupForm">

		<!-- SEGMENTO -->
		<mat-tab>

			<ng-container *ngTemplateOutlet="count; context: {step: 1, name: 'Selecione abaixo o seu segmento'}"></ng-container>

			<div class="content-form">
				<!-- <h3 class="d-block pb-2 text-center">Selecione abaixo o seu segmento:<span class="text-danger">*</span> </h3> -->
				<mat-button-toggle-group class="mb-4 mx-auto" formControlName="companyType">
					<p class="mb-2">Quero <b>antecipar</b> recebíveis</p>
					<mat-button-toggle value="SUPPLIER">
						<mat-icon class="text-secondary" >store</mat-icon>
						<span>Fornecedor do governo</span>
						<mat-icon class="checked">check_circle</mat-icon>
					</mat-button-toggle>


					<p class="mb-2 mt-3">Quero <b>comprar</b> recebíveis</p>

					<mat-button-toggle value="FINANCIAL_INSTITUTION">
						<mat-icon class="text-secondary">account_balance</mat-icon>
						<span>Instituição financeira</span>
						<mat-icon class="checked">check_circle</mat-icon>
					</mat-button-toggle>

					<mat-button-toggle value="FIDC">
						<mat-icon class="text-secondary">account_balance</mat-icon>
						<span>FIDC</span>
						<mat-icon class="checked">check_circle</mat-icon>
					</mat-button-toggle>

					<mat-button-toggle value="FOMENTO">
						<mat-icon class="text-secondary">account_balance</mat-icon>
						<span>Fomento</span>
						<mat-icon class="checked">check_circle</mat-icon>
					</mat-button-toggle>



				</mat-button-toggle-group>
			</div>

			<div  class="w-100" [matTooltip]="!signupForm.get('companyType').valid ? 'Para ir para próxima etapa selecione acima qual seu segmento' : ''">
				<button class="w-100 py-2" mat-stroked-button color="primary" [disabled]="!signupForm.get('companyType').valid" (click)="nextStep()">Próximo</button>
			</div>
		</mat-tab>

		<!-- DADOS DA EMPRESA -->
		<mat-tab >

			<ng-container *ngTemplateOutlet="count; context: {step: 2, name: 'Dados do usuário'}"></ng-container>

			<div class="content-form">
				<mat-form-field appearance="outline">
					<mat-label>CNPJ</mat-label>
					<input matInput required formControlName="company" mask="00.000.000/0000-00" placeholder="Digite o CNPJ da empresa" >
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Nome Completo</mat-label>
					<input matInput required formControlName="fullName" placeholder="Digite seu nome completo" >
				</mat-form-field>


				<mat-form-field appearance="outline">
					<mat-label>Telefone</mat-label>
					<input matInput required
            formControlName="phone"
            placeholder="Digite seu número de telefone com DDD"
            mask="(00) 000000009"
          >
				</mat-form-field>

        <section class="mt-2">
					<mat-checkbox class="text-initial" formControlName="checked">
						<span style="white-space: pre-wrap;">Ao informar meus dados, eu concordo com a <a rel="noopener" target="_blank"
								href="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/Pol%C3%ADtica+Privacidade.pdf">Politica de
								privacidade</a> e com os <a rel="noopener" target="_blank"
								href="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/Termos+de+uso.pdf">Termos de
								uso</a>.</span>
					</mat-checkbox>
				</section>
		  </div>




		  <div class="d-flex">
			  <button mat-stroked-button class="mr-2 w-50 py-2" color="primary" (click)="previousStep()">Voltar</button>

        <div  class="w-50" [matTooltip]="!signupForm.get('company').valid || !signupForm.get('fullName').valid || !signupForm.get('phone').valid || !signupForm.get('checked').valid ? 'Para ir para próxima etapa preencha os campos acima' : ''" *ngIf="!validating">
          <button mat-stroked-button class="py-2 w-100" color="primary" [disabled]="!signupForm.get('company').valid || !signupForm.get('fullName').valid || !signupForm.get('phone').valid || !signupForm.get('checked').valid" (click)="signup(signupForm.value)">Próximo</button>
        </div>

        <button mat-flat-button color="primary" class=" py-2 w-50"   disabled *ngIf="validating">
					Validando <mat-spinner class="d-inline-block ml-2" [diameter]="20"></mat-spinner>
				</button>
		  </div>

      <div class="alert alert-danger my-4 mat-elevation-z1" role="alert" *ngIf="hasError && !validating && !success">
        <mat-icon class="mr-2 align-middle">highlight_off</mat-icon>
        {{ errorMessage }}
      </div>
		</mat-tab>

		<!-- DADOS DE ACESSO -->
		<mat-tab>

			<ng-container *ngTemplateOutlet="count; context: {step: 3, name: 'Dados de acesso'}"></ng-container>

			<div class="content-form">
				<mat-form-field appearance="outline">
					<mat-label>E-mail</mat-label>
					<input matInput required placeholder="pat@example.com" formControlName="mail">
				</mat-form-field>

				<mat-form-field appearance="outline" class="mb-2">
					<mat-label>Senha</mat-label>
					<input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required autocomplete="off">
					<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hide">
						<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
					<mat-hint>Mínimo 6 dígitos</mat-hint>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Confirmar senha</mat-label>
					<input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" required autocomplete="off">
					<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hide">
						<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
					<mat-error *ngIf="signupForm.controls.confirmPassword.errors?.notSame">
						As senhas não coincidem
					</mat-error>
				</mat-form-field>

			</div>

			<div class="alert alert-success my-2 mat-elevation-z1" role="alert" *ngIf="success  && !validating &&  !hasError">
				<mat-icon class="mr-2 align-middle">check_circle</mat-icon>
				Conta criada com sucesso.
			</div>


			<div class="d-flex">
				<button mat-stroked-button color="primary" class="mr-2 w-50 py-2" (click)="previousStep()">Voltar</button>

				<button mat-flat-button color="primary" class=" py-2 w-50"  *ngIf="!validating"
					[disabled]="!signupForm.valid || signupForm.hasError('notSame')"
					(click)="completeSignup(signupForm.value)">Cadastrar</button>

				<button mat-flat-button color="primary" class=" py-2 w-50"   disabled *ngIf="validating">
					Validando <mat-spinner class="d-inline-block ml-2" [diameter]="20"></mat-spinner>
				</button>
			</div>

      <div class="alert alert-danger my-4 mat-elevation-z1" role="alert" *ngIf="hasError && !validating && !success">
				<mat-icon class="mr-2 align-middle">highlight_off</mat-icon>
				{{ errorMessage }}
			</div>
		</mat-tab>
	</form>
	</mat-tab-group>



<ng-template #count let-step="step" let-name="name">
	<div class="steps">
		<div class="steps__count">
			<b>{{step}} / 3</b>
		</div>
		<div class="steps__description">
			<span>{{name}}</span>
		</div>
	</div>
</ng-template>
