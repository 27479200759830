<div class="container-viewer" *ngIf="_editable">
	
	<angular-editor class="container-viewer-document" [(ngModel)]="_html" [config]="editorConfig"></angular-editor>

	<!-- <div class="toolbar pt-3" *ngIf="_editable">
		 <button mat-stroked-button class="px-5" (click)="exportAsPDF()">Baixar PDF</button>
		<button mat-stroked-button class="px-5  mx-2">Validar e Salvar</button>
		<button disabled mat-raised-button color="primary" class="px-5">Finalizar documento</button>

	</div> -->
	
	<div class="downlaod-block" *ngIf="isDownloading">
		<div class="mask-download"></div>
		<p><mat-icon class="heartbeat">download</mat-icon></p>
		<p>Baixando documento</p>
	</div>
</div>

<div id="content" hidden [innerText]="_html">

</div>

<div class="blankslate" *ngIf="!_editable">
	<img src="../../../assets/undraw_sync_files.svg" alt="selecione um documento" width="260px">
	<h1 class="text-secondary display-6 mt-3">Selecione um documento na lista ao lado.</h1>
</div>