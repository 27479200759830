<div  class="btn-logout">
  <button mat-stroked-button (click)="goToServiceDesk()" class="mr-2">Preciso de ajuda</button>
  <button mat-stroked-button (click)="logout()">Sair</button>

</div>

<div class="container">



  <ng-container *ngIf="user.tenant.tenantStatus === 'VALIDATING'">
    <img width="450px" class="mb-4" src="../../../assets/undraw_done_a34v.svg" alt="">
    <h1>Cadastro completado com sucesso!</h1>
    <p class="text-secondary">Estamos analisando os dados enviados e em breve você terá acesso a plataforma.</p>


    <button mat-raised-button color="primary" class="py-2 px-3" *ngIf="!loading" (click)="updateStatus()">Tentar novamente</button>
    <button class="py-2 px-3" mat-raised-button  color="primary" disabled *ngIf="loading">  Verificando <mat-spinner class="d-inline-block ml-2" [diameter]="20"></mat-spinner></button>
    <button mat-button color="primary" (click)="goToServiceDesk()" class="px-3 mt-2">Preciso de ajuda</button>


  </ng-container>
  <ng-container  *ngIf="user.tenant.tenantStatus !== 'VALIDATING'">
    <h1>Ops! Parece que existe um problema que esta restringindo seu acesso a plataforma</h1>
    <div style="max-width: 800px;">
      <p class="text-secondary">Se você já resolveu este problema ou acredita que ocorreu um engano, entre em contato conosco e solicite o desbloqueio.</p>
      
      <img width="450px" class="my-4" src="../../../assets/undraw_security_o890.svg" alt="">
    </div>
    
    <button mat-raised-button color="primary" class="py-2 px-3" *ngIf="!loading" (click)="updateStatus()">Tentar novamente</button>
    <button class="py-2 px-3" mat-raised-button  color="primary" disabled *ngIf="loading">  Verificando <mat-spinner class="d-inline-block ml-2" [diameter]="20"></mat-spinner></button>
    <button mat-button color="primary" (click)="goToServiceDesk()" class="px-3 mt-2">Preciso de ajuda</button>
  </ng-container>
  

  <img class="mt-5" width="150" rel="preconnect" src="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/images/BROADFACTOR_MARCA_FUNDOCINZA_20.png" alt="">

</div>