

<div class="container-error">
   <div class="container">
      <h1>OOPS!!</h1>
      <p>Algo inesperado aconteceu enquanto tentamos acessar esta página. 
         Tente novamente em breve.</p>
      
      
      <button mat-flat-button color="primary" (click)="refresh()">TENTAR NOVAMENTE</button>
      
      
      <img class="img" src="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/images/undraw_server_down_s4lk.svg" alt="">
   </div>

</div>