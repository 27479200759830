<div class="landing-page">

  <div class="position-absolute w-100">
    <section>

      <div class="header">
        <div class="text-center">
            <img class="mb-4" width="200" height="33" rel="preconnect" src="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/images/BROADFACTOR_MARCA_FUNDOCINZA_40%20(1).png" alt="">
            <h1 class="m-0 pt-3">Olá, seja bem vindo(a)!</h1>
            <h2>Você está acessando a proposta da cotação de número {{data?.quotation?.externalId ? data.quotation.externalId : 'Não informado'}}</h2>
  
            <p>Para obter mais informações, cadastre-se abaixo ou acesse sua conta.</p>
            <button mat-raised-button class="custom-button mt-5" [routerLink]="[ '/cadastro' ]">CADASTRE-SE</button>
            <button mat-button class="custom-button" [routerLink]="[ '/login' ]">Já tenho uma conta</button>

        </div>
        <img class="img-fluid illustration" src="../../../../assets/undraw_secure_data_0rwp.svg" alt="">
      </div>

      
      <svg id="curve" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1416.99 174.01">
          <path class="cls-1" d="M0,280.8S283.66,59,608.94,163.56s437.93,150.57,808,10.34V309.54H0V280.8Z" transform="translate(0-135.53)"/>
        </svg>
    </section>
    <section class="sec2">

      <p class="text-center">Veja abaixo os dados da proposta </p>
      <div class="container">

          <h1 class="text-secondary pt-4">DADOS DA PROPOSTA</h1>

          <h4 class="mt-2 text-secondary mb-0" style="line-height: 0.5;">Instituição financeira</h4>
          <h4 class="text-uppercase font-weight-bold">{{ data?.proposal?.institutionName ? data.proposal.institutionName: 'Não informado' }}</h4>
  
          <div class="card-group" id="content">

            <mat-card >
              <h4>Data da criação</h4>
              <h2>{{ data?.proposal?.createdAt ? ( data.proposal.createdAt ): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Valor do empréstimo</h4>
              <h2>{{ data?.proposal?.loanValue ? (data.proposal.loanValue | currency): 'Não informado' }}</h2>
            </mat-card>
           
            <mat-card>
              <h4>Valor liquido<mat-icon class="icon-help" matTooltip="Valor líquido que o fornecedor receberá no dia que ele aceitar a oferta.">help</mat-icon></h4>
              <h2>{{ data?.proposal?.netValue ? (data.proposal.netValue | currency): 'Não informado' }}</h2>
            </mat-card>
          
            <mat-card>
              <h4>Fluxo de pagamento<mat-icon class="icon-help" matTooltip="Valor comprometido de cada parcela do contrato para pagamento do Empréstimo.">help</mat-icon></h4>
              <h2>{{ data?.proposal?.paymentFlow ? (data.proposal.paymentFlow | currency ): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Valor da parcela</h4>
              <h2>{{ data?.proposal?.installmentValue ? (data.proposal.installmentValue | currency): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Número de parcelas</h4>
              <h2>{{ data?.proposal?.installmentNumber ? data.proposal.installmentNumber: 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Data da liberação</h4>
              <h2>{{ data?.proposal?.releaseDate ? (data.proposal.releaseDate | date:'dd/MM/yyyy'): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Data da primeira parcela</h4>
              <h2>{{ data?.proposal?.firstInstallmentDate ?( data.proposal.firstInstallmentDate | date:'dd/MM/yyyy'): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Data da última parcela</h4>
              <h2>{{ data?.proposal?.lastInstallmentDate ?( data.proposal.lastInstallmentDate | date:'dd/MM/yyyy'): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Modalidade</h4>
              <h2>{{ data?.proposal?.modality == 0 ? 'Empréstimo com garantia' : 'Cessão de direito'}}</h2>
            </mat-card> 

            <mat-card>
              <h4>Juros <mat-icon class="icon-help" matTooltip="Taxa (% ao mês) (taxa de juros caso empréstimo; taxa de deságio caso cessão).">help</mat-icon></h4>
              <h2>{{data?.interestRate ?  (data.interestRate | number ) + '%' : 'Não informado'}}</h2>
            </mat-card> 

            <mat-card>
              <h4>Juros mora <mat-icon class="icon-help" matTooltip="Taxa de correção aplicada em atrasos (% ao mês).">help</mat-icon></h4>
              <h2>{{ data?.moraInterestRate ? (data.moraInterestRate | number ) + '%' : 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>IOF</h4>
              <h2>{{ data?.proposal?.iofValue ? (data.proposal.iofValue | currency): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Multa <mat-icon class="icon-help" matTooltip="Multa aplicada em casos de atrasos.">help</mat-icon></h4>
              <h2>{{ data?.proposal?.fineValue ? (data.proposal.fineValue | currency): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>Seguro</h4>
              <h2>{{ data?.proposal?.insuranceValue ? (data.proposal.insuranceValue | currency) : 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>TAC <mat-icon class="icon-help" matTooltip="Tarifas de Abertura de crédito.">help</mat-icon></h4>
              <h2>{{ data?.proposal?.tacValue ? (data.proposal.tacValue | currency): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card>
              <h4>CET  <mat-icon class="icon-help" matTooltip="Custo efetivo total da operação (% ao mês).">help</mat-icon></h4>
              <h2>{{ data?.proposal?.cetValue ? (data?.proposal.cetValue | number ) + '%' : 'Não informado' }}</h2>
            </mat-card> 
          </div>

          <hr>

          <h1 class="text-secondary pt-4">DADOS DA COTAÇÃO</h1>
          
          <h4 class="mt-2 text-secondary mb-0" style="line-height: 0.5;">Fornecedor</h4>
          <h4 class="text-uppercase font-weight-bold">{{ data?.quotation?.supplierName ? data.quotation.supplierName: 'Não informado' }}</h4>
  
          <div class="card-group">

            <mat-card >
              <h4>Data da inclusão</h4>
              <h2>{{ data?.quotation?.date ? data.quotation.date : 'Não informado' }}</h2>
            </mat-card> 

            <mat-card >
              <h4>Valor da cotação</h4>
              <h2>{{ data?.quotation?.value ?( data.quotation.value | currency ): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card >
              <h4>Margem Disponível</h4>
              <h2>{{ data?.quotation?.availableMargin ?( data.quotation.availableMargin | currency ): 'Não informado' }}</h2>
            </mat-card> 

            <mat-card >
              <h4>Id da Cotação</h4>
              <h2>{{ data?.quotation?.externalId ? data.quotation.externalId: 'Não informado' }}</h2>
            </mat-card> 

            <mat-card >
              <h4>CPF/CNPJ do Fornecedor:</h4>
              <h2>{{ data?.quotation?.supplierCnpj ?( data.quotation.supplierCnpj | mask: 'CPF_CNPJ' ): 'Não informado' }}</h2>
            </mat-card> 

          </div>
        
      </div>

    </section>
  </div>

</div>


