import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export type viewerType = 'google' | 'office' | 'mammoth' | 'pdf';
@Component({
    selector: 'doc-viewer-angular',
    templateUrl: 'document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss']
})
export class DocViewerComponent implements OnChanges {
    
    type: 'img' | 'object' | 'iframe' | 'none' = 'none'
    @Input() fileName?: any | SafeResourceUrl;
    @Input() urlTypeFile?: any;
    @Input() blankslateMessage?: any;
    @Input() url: any;
    @Input() loading?: boolean;
    fileType: any;
    zoomValue = 100;
    
    TYPES: any = {
        'pdf':  () => {this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url); this.type = 'object'},
        'docx': () => {this.type = 'none'},
        'doc':  () => {this.type = 'none'},
        'xlsx': () => {this.type = 'none'},
        'ppt':  () => {this.type = 'none'},
        'pptx': () => {this.type = 'none'},
        'zip':  () => {this.type = 'none'},
    }

    constructor(private domSanitizer: DomSanitizer) { }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

        this.fileType =  this.urlTypeFile?.split('.').pop();

        if (changes?.loading?.currentValue !== changes?.loading?.previousValue )  {
            if(changes?.url && changes?.url?.currentValue !== changes?.url?.previousValue) {
                if (typeof this.url  === 'string' ) {
                    this.TYPES[this.fileType] ? this.TYPES[this.fileType]() : this.type = 'img'; 
                }
            }
        }
    }


    openInNewPage() {
        window.open(this.url, '_blank')
    }
    
    zoom( type: 'in' | 'out' ) {
        type == 'in' ? this.zoomValue += 25 :  this.zoomValue -= 25;
    }

    download() {
        const link = document.createElement("a");
        link.href = this.url;
        link.download = this.fileName;
        link.click();
    }
   
}
