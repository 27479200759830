import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup | any;
  hide = true;
  hasError = false;
  errorMessage = '';
  validating = false
  success = false;


  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router) {

  }

  ngOnInit() {
    this.signupForm = this.fb.group({
      companyType: [ '' , Validators.required],
      company: [ '' , Validators.required],
      fullName: [ '' , Validators.required],
      phone: [ '' , Validators.required],
      mail: [ '' , Validators.compose([Validators.email, Validators.required])],
      password: [ '' , [Validators.required, Validators.minLength(6)]],
      confirmPassword: [ '' , [Validators.required, Validators.minLength(6)]],
      checked: [ false , [Validators.required, Validators.requiredTrue]]
    }, { validator: this.checkPasswords });
  }

  createFormBuider() {

  }

  signup(resp: any) {
    this.hasError = false;
    this.validating = true;

    this.authService.signup(resp)
    .subscribe(
      (signupID: string) => {
        this.authService.setSignupId(signupID);
        this.validating = false;
        this.nextStep();
      },
      error => {
        this.success = false;
        this.validating = false;
        this.errorMessage = error ? error : 'Ocorreu um erro ao tentar criar sua conta. Verifique os dados digitados e tente entrar novamente.';
        this.hasError = true;
     }
    )
  }

  completeSignup(resp: any) {
    this.validating = true;
    this.hasError = false;
    this.authService.completeSignup(resp)
    .subscribe(
      () => {
        const mail = this.signupForm.get('mail').value;
        const password = this.signupForm.get('password').value;
        this.authService.login(mail, password).subscribe(
          () => {
            this.hasError = false;
            this.validating = false;
            this.success = true;
            this.router.navigate(['/dashboard']);
          },
          error => {
            this.success = false;
            this.validating = false;
            this.errorMessage = error ? error : 'Ocorreu um erro ao tentar criar sua conta. Verifique os dados digitados e tente entrar novamente.';
            this.hasError = true;
          }
        );
      },
      error => {
        this.success = false;
        this.validating = false;
        this.errorMessage = error ? error : 'Ocorreu um erro ao tentar criar sua conta. Verifique os dados digitados e tente entrar novamente.';
        this.hasError = true;
     }
    )
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null :  group.controls.confirmPassword.setErrors({notSame:true})
  }

  selectedIndex: number = 0;

 nextStep() {
    if (this.selectedIndex != 2) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  previousStep() {
    if (this.selectedIndex != 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  goToLogin() {
    window.history.pushState({}, '', '/login')
    window.location.reload();
  }

}
