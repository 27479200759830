import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserInterface } from 'src/app/interfaces/user';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  user?: UserInterface | any;
  canActivateRoute: boolean = false;
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    this.auth.user
    .pipe(take(1))
    .subscribe(user => {
      this.user = user;

      if(!this.user) {
        this.router.navigate(['/login']);
        return this.canActivateRoute = false;
      } else {
        const STATUS_TYPES: any = {
          'NEW': () => {
            if (state.url !== '/configuracao-inicial') { 
              this.router.navigate(['/configuracao-inicial']); 
              return this.canActivateRoute = false; 
            } else { return this.canActivateRoute =  true }
          },
          'VALIDATING': () => { 
            return this.validatePage(state);
          },
          'PENDING': () =>    {
            if (this.user.tenant.companyType === 'SUPPLIER' && state.url === '/cotacoes') {
                this.router.navigate(['/dashboard']);
                return  this.canActivateRoute = false;  
            } else { return this.canActivateRoute =  true }
  
          },
          'BLOCKED': () =>    { 
            return this.validatePage(state);
          },
          'OVERDUE': () =>    { 
            return this.validatePage(state);
           },
          'ENABLED': () => {
            if (state.url === '/cotacoes' && this.user.tenant.companyType === 'SUPPLIER') {
              this.router.navigate(['/dashboard']);
              return this.canActivateRoute = false;
            } else { return this.canActivateRoute = true }
          },
        }
  
        if (route.url.length < 1 || state.url === '/cadastro' || state.url === '/login') {
          this.router.navigate(['/dashboard']);
          return this.canActivateRoute = false;
        } else {
            return this.canActivateRoute = !!STATUS_TYPES[this.user.tenant.tenantStatus]();
        }
      }
    });

    return this.canActivateRoute;
  }
  
  validatePage(state: RouterStateSnapshot) {

    if (this.user.tenant.companyType !== 'SUPPLIER') {
      if (state.url !== '/validacao') { this.router.navigate(['/validacao']); return this.canActivateRoute = false; } 
      else { return this.canActivateRoute = true }

    } else {
      if (state.url === '/cotacoes' || state.url === '/validacao' ) {
        this.router.navigate(['/dashboard']);
        return this.canActivateRoute = false; 
      }
      return this.canActivateRoute = true
    }
  }


}
