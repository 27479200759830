import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[lastDigit]'
})
export class LastDigitDirective implements OnInit{

  constructor(private el: ElementRef<HTMLInputElement>) { }

  ngOnInit() {
    const initialValue = this.el.nativeElement.value;

    if (initialValue && initialValue.match(/\d/)) {
      this.formatInputValue(this.el.nativeElement);
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.formatInputValue(input);
  }

  private formatInputValue(input: HTMLInputElement) {
    const value = input.value.replace(/\D/g, ''); // Remove all non-numeric characters
    const formattedValue = this.formatarNumero(value);
    input.value = formattedValue;
  }

  formatarNumero(value: string): string {
    return value.length <= 1
      ? value
      : value.slice(0, -1) + '-' + value.slice(-1);
  }
}
