import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from '../components/notifier/notifier.component';
import { Notifier } from '../interfaces/notifier';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

  constructor(private snackBar: MatSnackBar) {}

  showNotification(notifier: Notifier) {
    let config: object = {
      data: {
        message: notifier.displayMessage ?? '',
        altMessage: notifier.altMessage ?? '',
        buttonTxt: notifier.buttonTxt ?? '',
        notifierList: notifier.notifierList ?? []
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: [notifier.messageType],
    };

    this.snackBar.openFromComponent(NotifierComponent, config);
  }
}

