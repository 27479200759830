import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-error',
  templateUrl: './message-error.component.html',
  styleUrls: ['./message-error.component.css']
})
export class MessageErrorComponent implements OnInit {
  @Input() message: string = 'Ocorreu um problema ao carregar dos dados.';
  @Input() altMessage: string = 'Contate o time de operações!';
  @Input() urlImage: string = 'assets/error.svg';

  constructor() { }

  ngOnInit(): void {
  }

}
