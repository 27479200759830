export enum RoutePages {
    Root = '',
    Login = 'login',
    RecoverPassword = 'recuperar_senha',
    SignUp = 'cadastro',
    ChangePassword = 'trocar_senha',
    ResetPassword = 'redefinir_senha',
    ExternalProposal = 'lp',
    Validation = 'validacao',
    Dashboard = 'dashboard',
    CreditOperation = 'operacao',
    Settings = 'configuracao',
    SettingsInitial = 'configuracao-inicial',
    Quotes = 'cotacoes',
    SupplierDetail = 'supplier-detail',

    ManagementUsers = 'usuarios',

    Proposals = 'propostas',

    Financial = 'financeiro',
 }
