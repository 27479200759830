import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { take, exhaustMap, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { throwError } from 'rxjs';
import { Notifier } from '../interfaces/notifier';
import { NotifierService } from '../services/notifier.service';


@Injectable({
    providedIn: 'root'
})

export class AuthInterceptorService implements HttpInterceptor {

	id?: string;
	constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
  ) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {

		return this.authService.user.pipe(
			take(1),
			exhaustMap(user => {

				const skipIntercept = req.headers.has('skip');

				if (skipIntercept) {
					const modifiedReq = req.clone({
						headers: req.headers.delete('skip')
					});
					return next.handle(modifiedReq);
				}

				const modifiedReq = req.clone({
				setHeaders: {
					'Content-Type': 'application/json',
					'Authorization':  'Bearer ' + user?.token
				}
				});

				return next.handle(modifiedReq).pipe(
					catchError(error => { return this.handleResponseError(error)})
				);
			})
		);

	}

	handleResponseError(errorRes: any) {

		if (errorRes.error.message === 'INVALID_TOKEN') {
			this.authService.logoutByInvalidToken()
		}

		if (errorRes.error && Array.isArray(errorRes.error)) {
			const errorArr: Notifier = {
        displayMessage: 'Ops! Algo não saiu como esperado.',
        messageType: 'errorNotifier',
        buttonTxt: 'OK',
        notifierList: errorRes.error
      }

      this.notifierService.showNotification(errorArr);
		}


		return throwError(errorRes);
	}
}
