import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Proposal } from '../interfaces/proposal';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  newProposal = new BehaviorSubject<any>(null);


  getProposal() : Observable<Proposal[]> {
    return this.http.get<Proposal[]>(environment.API_url + 'secure-api/proposal')
  }

  getProposalBySupplier() : Observable<Proposal[]> {
    return this.http.get<Proposal[]>(environment.API_url + 'secure-api/proposal/supplier')
  }

  getProposalByStatus(status: string) : Observable<Proposal[]> {
    return this.http.get<Proposal[]>(environment.API_url + 'secure-api/proposal/' + status)
  }

  getProposalCount() {
    return this.http.get(environment.API_url + 'secure-api/proposal/count')
  }

  save(proposal: Proposal): Observable<Proposal[]> {
    return this.http.post<Proposal[]>(environment.API_url + 'secure-api/proposal', proposal)
  }

  saveAndSend(proposal: Proposal) {
    return this.http.post(environment.API_url + 'secure-api/proposal/createAndSend', proposal, { observe: 'response' })

  }

  send(id: string) {
    return this.http.post(environment.API_url + 'secure-api/proposal/send/' + id, null)
  }

  cancel(id: string) {
    return this.http.patch(environment.API_url + 'secure-api/proposal/cancel/' + id, null)
  }

  reject(id: string, observation: string | null) {
    return this.http.put(environment.API_url + 'secure-api/proposal/analyze/' + id, {observation: observation, status: 'REJECTED' })
  }

  toApprove(id: string) {
    return this.http.put(environment.API_url + 'secure-api/proposal/analyze/' + id, {observation: null, status: 'SENT' })
  }

  getProposalById(id: string) {
    return this.http.get(environment.API_url + 'api/lp/' + id, {headers: {'skip':'true'}})
  }

  simulate(data: any) {
    return this.http.post(environment.API_url + 'api-fiducia/banking/simulate', data)
  }

  /* CET = Custo Efetivo Total */
  getPercentageCET(data: any) {
    return this.http.post(environment.API_url + 'api-fiducia/banking/cet', data)
  }
}
