import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  mail: string | any;
  successResponse = false;
  errorResponse = false;
  constructor(private authService: AuthService) { }

  ngOnInit() {}

  sendMail() {
    this.authService.resetPassword(this.mail)
    .subscribe(
      resp => {
        this.successResponse = true;
      },
      error => {
        this.errorResponse = true;
      }
    )
  }
}
